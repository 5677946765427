import React from 'react';
import Navigation from 'components2/Navigation';
import Layout from 'components2/Layout';
import Button from 'components2/Button';
import ScaleIn from 'components2/ScaleIn';
import SubFooter from 'components2/SubFooter';
import Carousel from 'components2/Carousel';
import { styled } from 'style2';

import usePlatform from 'hooks/usePlatform';
import useIsVisible from 'hooks/useIsVisible';

import discoElysium from 'images/box-art/discoElysium.jpg';
import divinity from 'images/box-art/divinity.jpg';
import dragonBallFighterZ from 'images/box-art/dragonBallFighterZ.jpg';
import efootballPes2020 from 'images/box-art/efootballPes2020.jpg';
import enterTheGungeon from 'images/box-art/enterTheGungeon.jpg';
import streetsOfRage from 'images/box-art/streetsOfRage.jpg';
import cuphead from 'images/box-art/cuphead.jpg';
import hades from 'images/box-art/hades.jpg';
import heaveHo from 'images/box-art/heaveHo.jpg';
import lethalLeagueBlaze from 'images/box-art/lethalLeagueBlaze.jpg';
import spelunky2 from 'images/box-art/spelunky2.jpg';
import unrailed from 'images/box-art/unrailed.jpg';
import spiritFarer from 'images/box-art/spiritFarer.jpg';
import movingOut from 'images/box-art/movingOut.jpg';
import nPlusPlus from 'images/box-art/nPlusPlus.jpg';
import towerFallAscension from 'images/box-art/towerFallAscension.jpg';
import untitledGooseGame from 'images/box-art/untitledGooseGame.jpg';
import cloudGaming from 'images/cloud-gaming/cloudGaming.svg';
import gameDev from 'images/cloud-gaming/gameDev.svg';
import crystal from 'images/cloud-gaming/crystal.svg';
import starWhite from 'images/cloud-gaming/starWhite.svg';
import circleWhite from 'images/cloud-gaming/circleWhite.svg';
import hills from 'images/cloud-gaming/hills.png';
import hills2x from 'images/cloud-gaming/hills@2x.png';
import { DOWNLOADS } from 'config';

const carouselItems = {
  hades: { name: 'Hades', image: hades },
  lethalLeagueBlaze: { name: 'Lethal League: Blaze', image: lethalLeagueBlaze },
  unrailed: { name: 'Unrailed!', image: unrailed },
  spelunky2: { name: 'Spelunky 2', image: spelunky2 },
  spiritFarer: { name: 'Spiritfarer', image: spiritFarer },
  cuphead: { name: 'Cuphead', image: cuphead },
  untitledGooseGame: { name: 'Untitled Goose Game', image: untitledGooseGame },
  heaveHo: { name: 'Heave Ho', image: heaveHo },
  nPlusPlus: { name: 'N++', image: nPlusPlus },
  movingOut: { name: 'Moving Out', image: movingOut },
  towerFallAscension: {
    name: 'Towerfall: Ascension',
    image: towerFallAscension
  },
  discoElysium: { name: 'Disco Elysium', image: discoElysium },
  divinity: { name: 'Divinity Original Sin I', image: divinity },
  dragonBallFighterZ: {
    name: 'Dragon Ball FighterZ',
    image: dragonBallFighterZ
  },
  enterTheGungeon: { name: 'Enter The Gungeon', image: enterTheGungeon },
  efootballPes2020: { name: 'Football PES2020', image: efootballPes2020 },
  streetsOfRage: { name: 'Streets Of Rage 4', image: streetsOfRage }
};

export default function CloudGaming() {
  return (
    <Layout
      title="Cloud Gaming"
      description="Discover and play video games in the cloud with other people on the Parsec Arcade."
    >
      <Header>
        <Navigation color="light" />
        <HeaderText>
          <h1>Stream Games to Any Device</h1>
          <p>
            With Parsec (and the Parsec Arcade), you can join, play, and share
            games online with anyone in the world, at any time. <LineBreak />{' '}
            Play your favorite games on any device with Parsec’s cutting edge
            game streaming technology.
          </p>
        </HeaderText>

        <section>
          <Carousel items={carouselItems} />
        </section>
      </Header>

      <MidBanner>
        <MidHeaderText>
          The global arcade where you <LineBreak /> can meet and play games{' '}
          <LineBreak /> with anyone.
        </MidHeaderText>
        <MidBoxes>
          <IndividualBox>
            <BoxImageWrapper>
              <img src={cloudGaming} width="98.494" height="76.39" alt="" />
            </BoxImageWrapper>
            <BoxHeader>Cloud Gaming</BoxHeader>
            <p>
              Everyone should have access to games without worrying about
              hardware. The Parsec Arcade is a peer–to–peer network of gamers
              playing, hanging out, and sharing a love for great games together
              powered by Parsec’s game streaming technology.
            </p>
          </IndividualBox>
          <IndividualBox>
            <BoxImageWrapper>
              <img src={gameDev} width="98.927" height="79" alt="" />
            </BoxImageWrapper>
            <BoxHeader>Add Parsec to Your Game</BoxHeader>
            <p>
              Unlock new ways to play your game. Add online multiplayer, let
              gamers play your game on any device, and do game testing from the
              cloud without sharing game files — all of this is possible with
              Parsec’s <a href="/sdk">game developer SDK</a>.
            </p>
          </IndividualBox>
          <IndividualBox>
            <BoxImageWrapper>
              <img src={crystal} width="85" height="84.998" alt="" />
            </BoxImageWrapper>
            <BoxHeader>License Parsec</BoxHeader>
            <p>
              <a href="/licensing">Build your own cloud gaming products.</a>{' '}
              Parsec has the leading game streaming technology, delivering games
              across the Internet via a 60FPS, UHD stream to more than 1 million
              gamers worldwide.
            </p>
          </IndividualBox>
        </MidBoxes>
      </MidBanner>

      <Footer />
    </Layout>
  );
}

function Footer() {
  const platform = usePlatform();
  const [ref, visible] = useIsVisible<HTMLDivElement>({
    threshold: 0.1
  });

  return (
    <SubFooter
      ref={ref}
      title="See it for yourself. Download for free and try Parsec."
      actions={
        <Button href={(platform && DOWNLOADS[platform]) || '/downloads'}>
          Download Now
        </Button>
      }
      backgroundColor="#ffae11"
      backgroundImage={hills}
      backgroundImageRetina={hills2x}
      backgroundSize="75rem"
      backgroundPosition="bottom -21rem right"
    >
      <Scale css={{ bottom: '41.9rem', right: '45.8rem' }} visible={visible}>
        <img src={starWhite} alt="" />
      </Scale>

      <Scale
        css={{ bottom: '39.6rem', right: '49.4rem' }}
        delay={100}
        visible={visible}
      >
        <img src={circleWhite} alt="" />
      </Scale>

      <Scale
        css={{ bottom: '33.4rem', right: '7.7rem' }}
        delay={300}
        visible={visible}
      >
        <img src={starWhite} alt="" />
      </Scale>
    </SubFooter>
  );
}

const Header = styled('header', {
  backgroundColor: '$pitchBlack',
  paddingBottom: '16rem',
  overflowX: 'hidden'
});

const HeaderText = styled('div', {
  display: 'grid',
  rowGap: '2rem',
  textAlign: 'center',
  margin: '15rem 4rem 0',
  color: '$consoleWhite'
});

const MidBanner = styled('div', {
  padding: '12rem 0 16rem',
  backgroundColor: '$computerBlack'
});

const LineBreak = styled('br', {
  large: {
    display: 'block'
  },
  display: 'none'
});

const MidHeaderText = styled('h2', {
  textAlign: 'center',
  fontSize: '$h2',
  lineHeight: '$h2',
  fontWeight: 'bold',
  margin: '0 4rem',
  color: '$consoleWhite'
});

const MidBoxes = styled('ul', {
  margin: '8rem 4rem 0',
  display: 'grid',
  gap: '2rem',
  justifyContent: 'center',
  full: {
    gridTemplateColumns: 'repeat(3, 38.8rem)'
  }
});

const IndividualBox = styled('li', {
  backgroundColor: '$carkol',
  borderRadius: '$medium',
  textAlign: 'center',
  color: '$rhyhorn',
  padding: '5rem 2.5rem',
  maxWidth: '59.2rem',
  full: {
    maxWidth: '38.8rem'
  }
});

const BoxHeader = styled('h3', {
  fontWeight: 'bold',
  color: '$consoleWhite',
  marginBottom: '2rem'
});

const BoxImageWrapper = styled('div', {
  height: '9.2rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  marginBottom: '4rem'
});

const Scale = styled(ScaleIn, {
  position: 'absolute'
});
